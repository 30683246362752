import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import firebase from "firebase";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";
import rootReducer from "./redux/reducers/rootReducer";
import { createFirestoreInstance } from "redux-firestore";
import App from "./App";
import {
  ReactReduxFirebaseProvider,
  getFirestore,
  getFirebase,
} from "react-redux-firebase";
import "./assets/scss/style.scss";
import * as serviceWorker from "./serviceWorker";

import { composeWithDevTools } from "redux-devtools-extension";

const firebaseConfig = {
  apiKey: "AIzaSyB3zVJ9xyfzftH2STs-6qDpBtzxuQvYEAU",
  authDomain: "dosal-ba23a.firebaseapp.com",
  databaseURL: "https://dosal-ba23a.firebaseio.com",
  projectId: "dosal-ba23a",
  storageBucket: "dosal-ba23a.appspot.com",
  messagingSenderId: "758802820779",
  appId: "1:758802820779:web:f8546db06902576231d1ee",
};

const middlewares = [thunk.withExtraArgument(getFirebase, getFirestore)];

const store = createStore(
  rootReducer,
  load(),
  composeWithDevTools(applyMiddleware(...middlewares, save()))
);

firebase.initializeApp(firebaseConfig);
firebase.firestore();

const rrfConfig = {
  userProfile: "Users",
  useFirestoreForProfile: true,
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <App />
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
